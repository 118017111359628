export const Explores = (props) => {
  return (
    <div id='explores' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Explores</h2>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-3 col-sm-6 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.img} alt='...' className='team-img' />
                  <div className='caption'>
                    <h4>{d.name}</h4>
                    <p>{d.text}</p>
                  </div>
                </div>
              </div>
            ))
            : 'loading'}
        </div>
      </div>
    </div>
    // <div id='explores' className='text-center'>
    //   <div className='container'>
    //     <div className='col-md-10 col-md-offset-1 section-title'>
    //       <h2>Explores</h2>
    //     </div>
    //     <div className='row'>
    //       {props.data
    //         ? props.data.map((d, i) => (
    //           <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
    //             {' '}
    //             <i className={d.icon}></i>
    //             <h3>{d.title}</h3>
    //             <p>{d.text}</p>
    //           </div>
    //         ))
    //         : 'Loading...'}
    //     </div>
    //   </div>
    // </div>
  )
}
